import React from 'react';
import { Col, Row, } from "react-bootstrap"
import ToggleButton from "./ToggleButton"
import { CSVLink } from "react-csv";

const OutputNavigation = ({ viewSettings, setSettings, toggle, calendar }) => {
    let csvData = []
    if (calendar.length > 1) {

        const header = Object.keys(calendar[0].view_data)
        csvData.push(header)
        calendar.forEach(d => {
            csvData.push(Object.values(d.view_data))
        })
    }
    return (
        <Row className="pt-3" style={{ display: "flex", flexDirection: "row", flexFlow: "wrap", justifyContent: "center" }}>
            <Col style={{ display: "flex", flexDirection: "row", flexFlow: "wrap", justifyContent: "center" }}>
                {calendar.length > 1 ? <CSVLink className="btn-sm m-1 btn-primary button" data={csvData}>CSV Export</CSVLink> : null}

                <ToggleButton settingkey="showchart" value={viewSettings.showchart} text={"Show diagram"} toggle={toggle} />
                <ToggleButton settingkey="firstdayonly" value={viewSettings.firstdayonly} text={"1st day of month"} toggle={toggle} />
                <ToggleButton settingkey="onedayperweek" value={viewSettings.onedayperweek} text={"One day per week"} toggle={toggle} />
            </Col>
            <Col>
                Show only from
                <input onChange={(e) => setSettings({ ...viewSettings, startdate: e.target.value })} type="date" value={viewSettings.startdate} className="m-1" />
                to
                 <input onChange={(e) => setSettings({ ...viewSettings, enddate: e.target.value })} type="date" value={viewSettings.enddate} className="m-1" />
            </Col>
        </Row>
    );
};

export default OutputNavigation;