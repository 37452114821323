import './../index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Col, Button } from 'react-bootstrap';
import InputPanel from "../components/InputPanel.js"
import OutputPanel from "../components/OutputPanel.js"
import { useState, useEffect } from "react"
import "react-datetime/css/react-datetime.css";

function Simulator() {
    const [config, _setConfig] = useState({})
    const [passConfig, setPassConfig] = useState(false)
    const setConfig = (values) => {
        let stateChanges = {}
        if (Array.isArray(values) && !Array.isArray(values[0])) {
            values = [values]
        }
        if (Array.isArray(values)) {
            values.forEach(e => stateChanges[e[0]] = e[1])
            let newconf = { ...config, ...stateChanges }
            let uid = localStorage.getItem('uid')
            if (uid === null) { uid = "nouid" }
            window.plausible('Calculate', { props: { uid: uid } })
            _setConfig(newconf)

        } else {
            alert("setConfig param not accepted")
        }

    }
    useEffect(() => {
        if (passConfig !== false) window.scrollBy(0, 300);
    }, [passConfig])
    const changed = JSON.stringify(passConfig) !== JSON.stringify(config)
    return (<>
        <Row className="pt-4 pb-0" style={{ backgroundColor: "#f2f2f2" }}>
            <InputPanel config={config} setConfig={setConfig} />
        </Row>
        <Row className="pt-0 pb-4" style={{ backgroundColor: "#f2f2f2" }}>
            <Col>
                <Button style={changed ? { border: "5px solid red" } : {}} className="btn-success" onClick={() => { setPassConfig(config) }}>Generate Prayer Times</Button>
                {changed ? " ⬅️ Click here to update data " : ""}
            </Col>
        </Row>
        <Row>
            <OutputPanel config={passConfig} />
        </Row>
    </>

    );
}

export default Simulator;
