import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import About from "./pages/About"
import Simulator from "./pages/Simulator"
import Formulas from "./pages/Formulas"
import { Container } from 'react-bootstrap';
import Header from "./components/Header"

const App = () => {
  return (
    <BrowserRouter>
      <Container fluid >
        <Header />
        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/formulas">
            <Formulas />
          </Route>
          <Route path="/simulator">
            <Simulator />
          </Route>
          <Route path="/">
            <Simulator />
          </Route>
        </Switch>
      </Container>
    </BrowserRouter>
  );
};

export default App;