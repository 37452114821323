import React from 'react';
import { Row, Col } from "react-bootstrap"
import { Link } from "react-router-dom"
const Header = () => {
    return (
        <>
            <Row className="pt-4 pb-4 bg-success text-light">
                <Col style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                    <strong style={{ marginRight: "10px" }}>Prayer Times Simulator</strong>
                    <i>For research purpose only. Do not use for your prayers. Refer to your local community.</i>
                </Col>
            </Row>
            <Row className="pt-1 pb-1 bg-light text-dark" >
                <Col style={{ display: "flex", flexDirection: "row" }}>
                    <Link style={{ marginRight: "10px" }} to="/simulator">Simulator</Link>
                    <Link style={{ marginRight: "10px" }} to="/formulas">Formulas</Link>
                    <Link style={{ marginRight: "10px" }} to="/about">About</Link>
                </Col>
            </Row>
        </>
    );
};

export default Header;