import React from 'react';
import { Button } from "react-bootstrap"

const ToggleButton = ({ settingkey, value, toggle, text }) => {
    const istrue = value === true
    return (
        <div>
            <Button
                onClick={() => { toggle(settingkey) }}
                className={`${istrue ? "btn-warning" : "btn-secondary"} btn-sm m-1`}>{istrue ? "✓" : "X"} {text}</Button>
        </div>
    );
};

export default ToggleButton;

