import React, { useEffect, useState } from "react";
import RenderInput from "./RenderInput";

const InputPanel = ({ config, setConfig }) => {
  const [groupedParams, setGroupedParams] = useState(false);
  useEffect(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_WEBAPI}parameters`, requestOptions)
      .then((response) => {
        console.log(response);
        return response.json();
      })
      .then((result) => {
        let params = result;
        let groupedParams = {};
        params.forEach((p) => {
          if (!groupedParams[p.group]) {
            groupedParams[p.group] = [];
          }
          groupedParams[p.group].push(p);
        });
        setGroupedParams(groupedParams);
      })
      .catch((error) => {
        alert(`error: ${error.message}`);
        console.log(error);
      });
  }, []);

  const changeParam = (key, value) => {
    setConfig([key, value]);
  };

  // validates if an option is applicable depending on config
  const validate = (param, config) => {
    if (!param.hasOwnProperty("dependencies")) return true;
    if (param.dependencies.length === 0) return true;
    return param.dependencies.every(
      (condition) => config[condition.expect] === condition.toBe
    );
  };

  return (
    <div>
      {!groupedParams ? (
        <h1 style={{ color: "grey" }}>Loading configuration dataset...</h1>
      ) : (
        <>
          {Object.keys(groupedParams).map((group, gi) => (
            <>
              {groupedParams[group].every(
                (p) => !validate(p, config)
              ) ? null : (
                <>
                  <div key={gi} className="pb-3">
                    <strong>{group}</strong>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {groupedParams[group].map((p, i) => {
                        if (!p.hasOwnProperty("dependencies"))
                          return (
                            <RenderInput
                              key={i}
                              param={p}
                              value={config[p.key]}
                              changeParam={changeParam}
                            />
                          );
                        if (validate(p, config))
                          return (
                            <RenderInput
                              key={i}
                              param={p}
                              value={config[p.key]}
                              changeParam={changeParam}
                            />
                          );
                        return null;
                      })}
                    </div>
                  </div>
                </>
              )}
            </>
          ))}
        </>
      )}
    </div>
  );
};

export default InputPanel;
