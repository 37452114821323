import React from 'react';

const About = () => {
    return (
        <div className="page">

            <h2>About</h2>
            <p>
                This project was created to support Ulemaa, researchers and islamic institutions
                in their efforts to standardize and unify prayer times calculation according to
                Islamic law and the related cosmic phenomena. It is particularly intended to
                provide a better understanding of the inner workings of the different calculation
                methods and parameters and how they lead to the resulting prayer times.
            </p>
            <p>
                This project is subject to continuous improvement and refinement. Any ideas, suggestions or feedback are highly welcomed.
                </p>
            <p>Also, if you are interested to contribute to the code base (JavaScript), please get in touch.
            </p>
            <h2>Important Notice</h2>
            <p>
                As stated in the header, this tool is solely for the purpose of research to be
                used by qualified researchers only. It is not ment to be used for performing Ibadaat.
                Please keep in mind that the matter of prayer times belongs to the domain of Fuqahaa'
                and has to be approved by respective Fiqh Councils. Therefore, regardless of the precision and quality
                of given calculation algorithms, individualls shall not rely on any
                programms or given data sets to make their own prayer time calendars, but solely on decisions
                of qualified Ulema and Fiqh Councils.
            </p>
            <h2>Sunset / Sunrise Angle</h2>
            <p>
                The angle for sunset and sunrise has to account for the upper disc of the sun
                as well as atmospheric refraction. The radius of the solar disc is considered at
                -0.25º. Assuming an average refraction this elevation is raised at sunset by 0.579º
                and at sunrise by 0.714º according to
                a <a href="https://www.jstor.org/stable/10.1086/378214?seq=1#metadata_info_tab_contents">study</a>    published by the Astronomical Society of the Pacific.
                Therefore the corrected angle for sunset could be set at -0.829º and for sunrise at -0.964º.
                This study as well as the <a href="http://www.icoproject.org/pdf/saifee_2106.pdf">research</a> work of Prof. Akbar Ali Saifee
                in this field could be applied to further adjust the refraction degree in dependency of season and other factors.
                Nevertheless, refraction remains a factor, that is not entirely predictable.
            </p>
            <h2>Taqdir Methods</h2>
            <p>
                <strong>Aqrab ul Bilad</strong>: Follows the method proposed by the late Prof. Muhammad Hawari (Islamic Centre Aachen).
                </p><p> <strong>Thuluth al Layl</strong>: As proposed by Ustaz Salim Albogha in 2018 (Islamic Centre Aachen).
            </p>

            <h2>Contact</h2>
            <p>
                This project does not claim to be free of errors and is subject to further
                improvement. Therefore, please contact me for any feedback, questions or suggestions via <a href="https://fncontact.com/3fffd207d3">this form</a>.
            </p>
        </div>
    );
};

export default About;