import React, { useEffect, useState } from "react";
import PrayerTimesViewer from "./PrayerTimesViewer";
import { Row, Col } from "react-bootstrap";

// import { PrayerTimesInterface } from "../calculation/PrayerTimesInterface";
import OutputNavigation from "./OutputNavigation";

const OutputPanel = ({ config }) => {
  //   const PTO = useMemo(
  //     () => (config !== false ? new PrayerTimesInterface(config) : null),
  //     [config]
  //   );
  const [PTO, setPTO] = useState(null);
  //   let calendar = [];
  //   if (config !== false) calendar = PTO.getCalendar();
  const currentyear = config.year ? config.year : new Date().getFullYear();

  const [viewSettings, setSettings] = useState({
    firstdayonly: false,
    onedayperweek: false,
    showchart: true,
    startdate: `${currentyear}-01-01`,
    enddate: `${currentyear}-12-31`,
  });

  useEffect(() => {
    if (config) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      console.log(config);
      var raw = JSON.stringify(config);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(process.env.REACT_APP_WEBAPI, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          setPTO(result);
        })
        .catch((error) => {
          alert(error);
          console.log("Error:", error);
        });
    }
  }, [config]);

  useEffect(() => {
    const currentyear = config.year ? config.year : new Date().getFullYear();

    setSettings((v) => ({
      ...v,
      startdate: `${currentyear}${v.startdate.slice(4)}`,
      enddate: `${currentyear}${v.enddate.slice(4)}`,
    }));
  }, [config.year]);

  if (config === false) {
    return null;
  }

  const toggle = (settingkey) => {
    let newSettings = {
      ...viewSettings,
      [settingkey]: !viewSettings[settingkey],
    };
    if (settingkey === "firstdayonly" && viewSettings[settingkey] === false) {
      newSettings.onedayperweek = false;
    }
    if (settingkey === "onedayperweek" && viewSettings[settingkey] === false) {
      newSettings.firstdayonly = false;
    }
    setSettings(newSettings);
  };

  return (
    <>
      {PTO && PTO.calendar.length > 0 ? (
        <>
          <OutputNavigation
            calendar={PTO.calendar}
            viewSettings={viewSettings}
            setSettings={setSettings}
            toggle={toggle}
          />

          <Row>
            <Col>
              <PrayerTimesViewer
                calendar={PTO ? PTO.calendar : []}
                viewSettings={viewSettings}
              />
            </Col>
          </Row>
        </>
      ) : (
        <>
          <h1>LOADING....</h1>
        </>
      )}
    </>
  );
};

export default OutputPanel;
