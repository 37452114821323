import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { v4 as uuidv4 } from "uuid";
const { detect } = require("detect-browser");
const browser = detect();

if (browser && browser.name !== "chrome") {
  console.log(
    "It is recommended to use the Google chrome browser for this app."
  );
}

let uid;
if (localStorage.getItem("uid") === null) {
  uid = uuidv4();
  localStorage.setItem("uid", uid);
} else {
  uid = localStorage.getItem("uid");
}
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
