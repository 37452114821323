import React from 'react';
import { MathComponent } from 'mathjax-react'

const Formulas = () => {
    return (
        <div className="page">
            <h2>Formulas </h2>
            <br /><hr />
            <h5>Declination</h5>
            <MathComponent tex={String.raw`\delta = -23.4365º \times \cos (OD+10+0.5) \times \frac{360}{DaysPerYear} \times \frac{\pi}{100}`} />
            <MathComponent tex={String.raw`OD = ordinal date `} />
            <br /><hr />
            <h5>Equation of Time</h5>
            <MathComponent tex={String.raw`eqot = -1 \times -0.171 \times \sin(0.0337 \times OD + 0.465) - 0.1299 \times \sin(0.01787 \times OD - 0.168) \times 60`} />

            <br /><hr />
            <h5>Solar Midday Time</h5>
            <MathComponent tex={String.raw`12:00 + 60 \times GMT - 4\times \Phi + eqot`} />
            <MathComponent tex={String.raw`\Phi = Longitude`} />
            <br /><hr />
            <h5>Lowest Solar Altitude</h5>
            <MathComponent tex={String.raw`\sin(\alpha) = \sin(\lambda) \times \sin(\delta) + \cos(\lambda) \times \cos(\delta) \times \cos(\pi)`} />
            <MathComponent tex={String.raw`\lambda = Latitude`} />
            <br /><hr />
            <h5>Convert Solar Alittude to Sun Hour Angle </h5>
            <MathComponent tex={String.raw`ha = \arccos(  \frac{ \sin(alt) -  \sin(decl) \times \sin(lat)  }{\cos(decl) \times \cos(lat)}    )`} />
            <br /><hr />
            <h5>Adjusting Solar Alittude by Observer Elevation </h5>
            <MathComponent tex={String.raw`\alpha -2.076 \times \frac{\sqrt{m}}{60}`} />
            <br /><hr />
        </div>
    );
};


export default Formulas;