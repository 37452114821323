import React from "react";
import { Scatter } from "react-chartjs-2";

const ChartView = ({ calendar }) => {
  const fajr = {
    label: "Fajr",
    data: [],
    backgroundColor: "red",
  };
  const shuruq = {
    label: "Shuruq",
    data: [],
    backgroundColor: "orange",
  };
  const zuhr = {
    label: "Zuhr",
    data: [],
    backgroundColor: "green",
  };
  const assr = {
    label: "Assr",
    data: [],
    backgroundColor: "pink",
  };
  const maghrib = {
    label: "Maghrib",
    data: [],
    backgroundColor: "violet",
  };
  const ishaa = {
    label: "Ishaa",
    data: [],
    backgroundColor: "black",
  };
  const datetotimescale = (date) => {
    const date_object = new Date(date);
    if (date_object instanceof Date && !isNaN(date_object)) {
      let hrs = date_object.getUTCHours();
      let mins = date_object.getUTCMinutes();
      let secs = date_object.getUTCSeconds();
      console.log(date_object);
      return hrs + mins / 60 + secs / 60 / 60;
    } else {
      console.log(null);
      return null;
    }
  };
  calendar.forEach((day) => {
    fajr.data.push({
      lbl: day.view_data.Fajr,
      footer: day.view_data["Gregorian Date"],
      x: day.astro_data.ordinal_date,
      y:
        day.astro_data.fajr !== null
          ? datetotimescale(day.astro_data.fajr)
          : null,
    });
    shuruq.data.push({
      lbl: day.view_data.Shuruq,
      footer: day.view_data["Gregorian Date"],
      x: day.astro_data.ordinal_date,
      y:
        day.astro_data.shuruq !== null
          ? datetotimescale(day.astro_data.shuruq)
          : null,
    });
    zuhr.data.push({
      lbl: day.view_data.Zuhr,
      footer: day.view_data["Gregorian Date"],
      x: day.astro_data.ordinal_date,
      y:
        day.astro_data.zuhr !== null
          ? datetotimescale(day.astro_data.zuhr)
          : null,
    });
    assr.data.push({
      lbl: day.view_data.Assr,
      footer: day.view_data["Gregorian Date"],
      x: day.astro_data.ordinal_date,
      y:
        day.astro_data.assr !== null
          ? datetotimescale(day.astro_data.assr)
          : null,
    });
    maghrib.data.push({
      lbl: day.view_data.Maghrib,
      footer: day.view_data["Gregorian Date"],
      x: day.astro_data.ordinal_date,
      y:
        day.astro_data.maghrib !== null
          ? datetotimescale(day.astro_data.maghrib)
          : null,
    });
    ishaa.data.push({
      lbl: day.view_data.Ishaa,
      footer: day.view_data["Gregorian Date"],
      x: day.astro_data.ordinal_date,
      y:
        day.astro_data.ishaa !== null
          ? datetotimescale(day.astro_data.ishaa)
          : null,
    });
  });

  const data = {
    // labels: ["January", "February", "March", "April", "May"],
    datasets: [fajr, shuruq, zuhr, assr, maghrib, ishaa],
  };

  const options = {
    elements: {
      point: {
        radius: 1,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            return [
              context.dataset.data[context.dataIndex].lbl,
              context.dataset.data[context.dataIndex].footer,
            ];
          },
        },
      },
    },
    scales: {
      x: {
        max: 366,
        grid: {
          display: true,
          drawTicks: true,
          drawBorder: true,
          drawOnChartArea: true,
        },
      },
      y: {
        max: 24,
      },
    },
  };

  return (
    <div style={{ position: "relative", height: "350px" }}>
      <Scatter
        data={data}
        height={350}
        options={{ ...options, maintainAspectRatio: false }}
      />
    </div>
  );
};

export default ChartView;
